// Required JS from local files
import { Context, addInitialFunction, dfmAlert } from './dfm5';

// CSS
import './homepage-new2.css';

$(document).ready(function(){
   $("#homepage-watch-video").on("click", () => {
      dfmAlert(`
         <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/KJT1fti5lwU"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      `);
   })
   addInitialFunction(function(){
      console.log("Redirecting to dashboard...");
      if(Context.user)window.location = 'dashboard.php';
   });
});